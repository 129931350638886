

/***********************************
************* REGULAR **************
***********************************/

@font-face {
    font-family:'Gotham Pro';
	src: url('/assets/fonts/GothaProReg.eot');
	src: local('☺'), url('/assets/fonts/GothaProReg.woff') format('woff'), url('/assets/fonts/GothaProReg.ttf') format('truetype'), url('/assets/fonts/GothaProReg.svg') format('svg');
	font-weight: normal;
    font-style: normal;
    font-display: swap;
}