:import('../../shared/styles/vars.css') {
  __value__cWhite__0: cWhite;
  __value__headerHorizontalOffset__1: headerHorizontalOffset;
  __value__pageTopPadding__2: pageTopPadding
}


:export {
  cWhite: __value__cWhite__0;
  headerHorizontalOffset: __value__headerHorizontalOffset__1;
  pageTopPadding: __value__pageTopPadding__2
}


.LandingPage {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center;
  justify-content: space-around;
  height: calc(100vh - 165px);
  position: absolute;
  top: 0;
  left: 0;
  padding-top: __value__pageTopPadding__2
}


@media (max-width: 767.0px) and (-webkit-min-device-pixel-ratio: 2) {


.LandingPage {
      height: calc(100vh - 175px);
      padding-top: 26px
}
  }


.LogoWrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center;
}


.Logo {
  width: 73px;
  height: 35px;
  background-image: url('/assets/img/logo-small.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 20px;
}


.LogoText {
  width: 300px;
  height: 42px;
  background-image: url('/assets/img/logo-text.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}


.Title {
  margin-bottom: 50px;
}


.BtnsWrap {}


.Btn:not(:last-child) {
    margin-bottom: 10px;
  }


.Text {
  font-size: 11px;
  font-weight: 100;
  letter-spacing: 0.5px;
  line-height: 16px;
  color: __value__cWhite__0;
  padding: 0 15px;
  text-align: center
}


@media (max-width: 677.0px) {


.Text {
    padding: 0 12px;
    font-size: 10px;
    margin-bottom: 10px
}
  }


.Link {
  text-decoration: underline;
  color: __value__cWhite__0;
}


.WorldsWrap {
  width: 320px;
  height: calc(100vh - 450px);
  margin: 0 auto
}


@media (max-width: 767.0px) {


.WorldsWrap {
    height: calc(100vh - 435px)
}


:global(.AppOK) .WorldsWrap {
      height: calc(100vh - 400px)
}
  }
