:import('../../styles/vars.css') {
  __value__cWhite__0: cWhite;
  __value__cTradewind__1: cTradewind
}
:export {
  cWhite: __value__cWhite__0;
  cTradewind: __value__cTradewind__1
}
.UserLives {
	color: __value__cWhite__0;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	        justify-content: space-between;
	width: 300px
}
@media (max-width: 767.0px) {
  .UserLives {
		width: 180px
}
  }
