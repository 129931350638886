:import('../../styles/vars.css') {
  __value__cWhite__0: cWhite;
}
:export {
  cWhite: __value__cWhite__0;
}
.PageTitle {
	color: __value__cWhite__0;
	font-size: 20px;
	text-align: center;
}
