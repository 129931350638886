:import('./shared/styles/vars.css') {
  __value__cTradewind__0: cTradewind;
  __value__pageTopPadding__1: pageTopPadding;
  __value__headerHorizontalOffset__2: headerHorizontalOffset;
}
:export {
  cTradewind: __value__cTradewind__0;
  pageTopPadding: __value__pageTopPadding__1;
  headerHorizontalOffset: __value__headerHorizontalOffset__2;
}
.App {
  width: 100vw;
  position: relative;
  overflow: hidden;
  background-color: __value__cTradewind__0;
}
.PageWrap {
  position: relative;
  padding: __value__pageTopPadding__1 0 0;
  width: 100%;
  height: 100%;
  z-index: 1
}
@media (max-width: 767.0px) and (-webkit-min-device-pixel-ratio: 2) {
.PageWrap {
      padding: __value__pageTopPadding__1 0 80px
}
  }
.Header {
	position: absolute;
	top: 10px;
	left: __value__headerHorizontalOffset__2;
	right: __value__headerHorizontalOffset__2;
  margin: 0 auto;
  z-index: 2;
}
.Footer {
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  z-index: 2;
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s, visibility 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s, visibility 0.3s ease 0.5s
  /* @mixin mediaTablet {
    bottom: 80px;
  } */
}
@media (max-width: 1024.0px) {
.Footer.OK {
      bottom: 108px
  }
  }
.FooterHidden {
  -webkit-transition: opacity 0.3s ease, visibility 0.3s ease;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 0;
  visibility: hidden;
}
.CheatPanel {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0
}
@media (max-width: 1024.0px) {
.CheatPanel {
    top: auto;
    bottom: 0
}
  }
.PreloaderWrap {
  background: __value__cTradewind__0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  width: 100vw;
  height: 100vh;
}
.Tutorial {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
