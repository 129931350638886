

.UpdateBtn {
  background-image: url('/assets/img/update-icon.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  display: block;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out
}

@media (min-width: 1024.0px) {
    .UpdateBtn:hover {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
    }
  }

@media (max-width: 767.0px) {

.UpdateBtn {
    width: 35px;
    height: 35px
}
  }

@media (max-width: 370.0px) {

.UpdateBtn {
    width: 30px;
    height: 30px
}
  }

.Transparent {
  opacity: 0.44;
}