:import('../../shared/styles/vars.css') {
  __value__cWhite__0: cWhite;
  __value__headerHorizontalOffset__1: headerHorizontalOffset;
  __value__hHeader__2: hHeader;
  __value__wLangBtn__3: wLangBtn
}

:export {
  cWhite: __value__cWhite__0;
  headerHorizontalOffset: __value__headerHorizontalOffset__1;
  hHeader: __value__hHeader__2;
  wLangBtn: __value__wLangBtn__3
}

@-webkit-keyframes levelsShow {
  0%   {opacity: 0}
  100% {opacity: 1}
}

@keyframes levelsShow {
  0%   {opacity: 0}
  100% {opacity: 1}
}

.LevelsPage {
  width: 100%;
  padding: __value__hHeader__2 __value__headerHorizontalOffset__1 0;
  height: calc(100vh - 135px)
}

:global(.AppOK) .LevelsPage {
    height: calc(100vh - 160px)
}

.LevelsPageTitle {
  margin-bottom: 10px;
}

.LevelsContent {
  padding-top: 37px;
  -webkit-animation: levelsShow 1s ease;
          animation: levelsShow 1s ease
}

@media (max-width: 1024.0px) {

.LevelsContent {
    padding-top: 32px
}
  }

.LevelsListScrollWrap {
  height: calc(100vh - 315px)
}

@media (max-width: 1024.0px) {

.LevelsListScrollWrap {
    height: calc(100vh - 330px)
}
  }

@media (max-width: 767.0px) {

.LevelsListScrollWrap {
    height: calc(100vh - 330px)
}
  }

@media (max-width: 767.0px) and (-webkit-min-device-pixel-ratio: 2) {

.LevelsListScrollWrap {
      height: calc(100vh - 330px)
}
  }

.LevelsListScrollWrap.OK {
    /*padding-top: 37px;*/
    height: calc(100vh - 255px)
    /*animation: levelsShow 1s ease;*/
  }

@media (max-width: 1024.0px) {

.LevelsListScrollWrap.OK {
      /*padding-top: 32px;*/
      height: calc(100vh - 250px)
  }
  }

@media (max-width: 1024.0px) and (-webkit-min-device-pixel-ratio: 2) {

.LevelsListScrollWrap.OK {
        height: calc(100vh - 250px)
  }
  }

@media (max-width: 1024.0px) {

:global(.AppOK) .LevelsListScrollWrap.OK {
        height: calc(100vh - 310px)
  }
  }

.LevelList {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
          justify-content: center;
  max-width: 850px;
  margin: 0 auto;
}

.LevelItem {
  width: 135px;
  height: 85px;
  padding: 5px 8px;
  margin: 0 13px 33px 13px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease
}

@media (min-width: 1024.0px) {
    .LevelItem:hover:not(.Locked) {
      background-color: rgba(253, 253, 253, 0.4);
    }
  }

@media (max-width: 767.0px) {

.LevelItem {
    width: 43px;
    height: 43px;
    margin: 0 4px 17px 4px
}
  }

.LevelItemWrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  height: 100%;
  cursor: pointer;
  padding: 10px 0
}

@media (max-width: 767.0px) {

.LevelItemWrap {
    padding: 0
}
  }

.LevelItemNumber {
  font-size: 32px;
  color: __value__cWhite__0;
  text-align: center
}

@media (max-width: 767.0px) {

.LevelItemNumber {
    font-size: 22px
}
  }

.LevelItemStars {
  width: 100%;
}

.BackBtn {
  position: absolute;
  left: 0;
}

.Completed {
  background-color: blue;
}

.Locked {
  opacity: 0.6
}

.Locked .LevelItemWrap {
    cursor: default;
  }

.ToBeContinued {
  font-size: 18px;
  color: __value__cWhite__0;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.LevelLocked {
  width: 24px;
  height: 24px;
  background-image: url('/assets/img/lock-icon.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent
}

@media (max-width: 767.0px) {

.LevelLocked {
    width: 11px;
    height: 11px
}
  }

.StarsCounter {
  margin: 0 auto 10px auto;
}
