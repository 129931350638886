:import('../../styles/vars.css') {
  __value__cWhite__0: cWhite;
  __value__hOkShareBtn__1: hOkShareBtn;
}

:export {
  cWhite: __value__cWhite__0;
  hOkShareBtn: __value__hOkShareBtn__1;
}

.OKShareBtn {
  width: 137.75px;
  height: __value__hOkShareBtn__1;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  background-color: #ed7a00;
  background: -webkit-gradient(linear, left bottom, left top, from(#ed7a00) , to(#ffa049));
  background: -webkit-linear-gradient(bottom, #ed7a00 , #ffa049);
  background: linear-gradient(to top, #ed7a00 , #ffa049);
  padding: 0 10px 0 25px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 11px;
  color: __value__cWhite__0;
  border-radius: 0;
  cursor: pointer;
  position: relative;
}

.OK {
  width: 20px;
  height: 20px;
  background-image: url('/assets/img/ok-icon.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.OKShareBtnTitle {
  line-height: 10px;
}
