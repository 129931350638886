

* {
  box-sizing: border-box;
  list-style: none;
}

html {
  font-size: 16px;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  font-family:'Gotham Pro', Arial, sans-serif;
  font-weight: 400px;
  overflow: hidden;
  /* remove scrollbar */
  /* Chrome */
  /* &::-webkit-scrollbar {
    display: none;
  } */
  /* Internet Explorer and Edge */
  /* -ms-overflow-style: none; */
}

a {
  text-decoration: none;
}