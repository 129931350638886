:import('../../styles/vars.css') {
  __value__cWhite__0: cWhite;
  __value__cRed__1: cRed
}

:export {
  cWhite: __value__cWhite__0;
  cRed: __value__cRed__1
}

.AddBtn {
  position: relative;
  width: 43px;
  height: 43px;
  padding: 0;
  background-color: rgba(255, 255, 255, .2);
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  outline: none;
  -webkit-transition: border-color 0.3s ease-out;
  transition: border-color 0.3s ease-out
}

@media (max-width: 767.0px) {

.AddBtn {
    width: 30px;
    height: 30px
}
  }

@media (min-width: 1024.0px) {
    .AddBtn:hover:not(.AddBtnDisabled) {
      border-color: rgba(255, 255, 255, 0.4);
    }
    .AddBtn:hover:not(.AddBtnDisabled) .AddBtnPlusIcon {
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg);
    }
  }

.AddBtnPlusIcon {
  background-image: url('/assets/img/plus-icon.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  width: 23px;
  height: 24px;
  -webkit-transform: none;
          transform: none;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out
}

@media (max-width: 767.0px) {

.AddBtnPlusIcon {
    width: 15px;
    height: 15px
}
  }

.AddBtnDisabled {
  opacity: 0.6;
  cursor: default;
}
