:import('../../styles/vars.css') {
  __value__cWhite__0: cWhite
}


:export {
  cWhite: __value__cWhite__0
}


.CustomListItem {
	font-size: 18px;
	color: __value__cWhite__0;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	        justify-content: space-between;
	padding: 0 25px 0 15px;
	background-color: rgba(255, 255, 255, 0.2);
	border: 1px solid transparent;
	height: 49px;
	margin-bottom: 16px;
	position: relative;
	-webkit-transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
	transition: background-color 0.3s ease-out, border-color 0.3s ease-out
}


.CustomListItem:not(.Blocked) {
		cursor: pointer;
	}


@media (min-width: 1024.0px) {
	  .CustomListItem:not(.Blocked):hover {
	    background-color: rgba(255, 255, 255, 0.1);
	    border-color: rgba(255, 255, 255, 0.4);
	  }
  }


.CustomListItemTitle {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}


.CustomListItemRight {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	        justify-content: space-between;
	text-align: right;
}


.Virtual .CustomListItemRight {
	width: 50px;
}


.CustomListItemStarsInfo {
	margin-top: 3px;
	width: 62px;
}


.CustomListItemStarsRequired {
	margin-top: 3px;
	width: 34px;
}


.Virtual .CustomListItemStarsRequired {
	display: none;
}


.StarIcon {
	width: 20px;
	height: 20px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('/assets/img/star-filled-gold.svg');
	margin-right: 5px;
}


.Virtual .StarIcon {
	display: none;
}


.StarIconGrey {
	background-image: url('/assets/img/star-filled-grey.svg');
	margin-right: 8px;
}


.Blocked {
	color: #808081;
}


.SizeSmall .CustomListItemStarsInfo {
		font-size: 14px;
	}


.LockIcon {
	width: 20px;
	height: 20px;
	background-image: url('/assets/img/lock-icon-grey.svg');
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-color: transparent;
	margin-right: 5px;
}


.Hint {
	position: absolute;
	top: -45%;
	width: 60%;
	padding: 10px;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	left: 50%;
	background-color: cTradewind;
	color: __value__cWhite__0;
	border: 1px solid hsla(0,0%,100%, 0.5);
	text-align: center;
	font-size: 15px;
	line-height: 1.2;
	font-style: italic;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity 0.3s ease, visibility 0.3s ease, left 0.3s ease;
	transition: opacity 0.3s ease, visibility 0.3s ease, left 0.3s ease;
}
