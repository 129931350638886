

.BackBtn {
  width: 24px;
  height: 43px;
  background-image: url('/assets/img/back-button.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease
}

@media (max-width: 767.0px) {

.BackBtn {
    width: 18px;
    height: 29px
}
  }

@media (max-width: 370.0px) {

.BackBtn {
    width: 15px;
    height: 25px
}
  }

@media (min-width: 1024.0px) {
    .BackBtn:hover {
      -webkit-transform: scaleY(0.9);
              transform: scaleY(0.9);
    }
  }

.Reversed {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg)
}

@media (min-width: 1024.0px) {
    .Reversed:hover {
      -webkit-transform: scaleY(0.9) rotate(180deg);
              transform: scaleY(0.9) rotate(180deg);
    }
  }

.Transparent {
  opacity: 0.44;
}