

.ModalManager {

}

.Modal {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  -webkit-transition: opacity 1s ease, visibility 1s ease, z-index 1s ease;
  transition: opacity 1s ease, visibility 1s ease, z-index 1s ease
}

.Modal.Show {
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }