:export {
  time: 0.4s;
  easing: ease-in-out;
}

/*
  forward transition = from right to left
  back transition = from left to right
*/

/**
 * After changing animation time, we also need to change it in shared/constants.js const PAGE_ANIMATION_TIME
 */

.page-forward-enter {
  opacity: 0;
  z-index: 1;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}

.page-forward-enter.page-forward-enter-active,
.page-back-enter.page-back-enter-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.page-forward-exit,
.page-back-exit {
  opacity: 1;
}

.page-forward-exit.page-forward-exit-active {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
  -webkit-transition: opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.page-back-enter {
  opacity: 0;
  z-index: 1;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
}

.page-back-exit.page-back-exit-active {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  -webkit-transition: opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}