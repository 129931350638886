:import('../../styles/vars.css') {
  __value__cWhite__0: cWhite;
}


:export {
  cWhite: __value__cWhite__0;
}


.StarsWrap {
	margin: 0 auto;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
}


@media (max-width: 767.0px) {
    .ResizeOnMobile .Star {
    	width: 12px;
    	height: 12px;
    	margin: 0 2px;
    }
  }


.Star {
	width: 24px;
	height: 23px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	margin: 0 3%;
}


.StarOutline {
	background-image: url('/assets/img/star-outline.svg');
}


.StarFilled {
	background-image: url('/assets/img/star-filled-white.svg');
}
