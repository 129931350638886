:import('../../styles/vars.css') {
  __value__cWhite__0: cWhite;
}

:export {
  cWhite: __value__cWhite__0;
}

.Settings {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
}

.SettingsBtn {
  width: 40px;
  height: 40px;
  background-image: url('/assets/img/settings-icon.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease
}

@media (max-width: 767.0px) {

.SettingsBtn {
    width: 30px;
    height: 30px
}
  }

@media (min-width: 1024.0px) {
    .SettingsBtn:hover {
      -webkit-transform: rotate(142deg);
              transform: rotate(142deg);
    }
  }
