:import('../../styles/vars.css'){
  __value__cTradewind__0: cTradewind;
  __value__cWhite__1: cWhite;
}

:export{
  cTradewind: __value__cTradewind__0;
  cWhite: __value__cWhite__1;
}

.Hint{
  position: relative;
}

.HintWrap {
  width: 100%;
  height: 100%;
}

.TextWrap {
  position: absolute;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center;
  opacity: 0;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in
}

.TextWrap.Visible {
    opacity: 1;
  }

.Text {
  color: __value__cWhite__1;
  text-align: center;
}

.Btn {
  background-color: __value__cWhite__1;
  color: #828282;
  border-radius: 2px;
  font-size: 14px;
  padding: 10px 0;
  min-width: 100px;
  text-align: center;
  font-family:'Gotham Pro';
  font-weight: 500;
  margin-top: 14px;
  cursor: pointer
}

.Btn:focus {
    outline: none;
  }

.Btn.Hidden {
    display: none;
  }

.SkipBtn {
  composes: Btn;
  background: __value__cTradewind__0;
  color: __value__cWhite__1;
  padding: 10px;
  margin: 0;
  outline: none;
  border: 1px solid white;
}

.SkipBtnPosition {
  position: absolute;
  bottom: 5%;
  right: 50%;
  -webkit-transform: translateX(50%);
          transform: translateX(50%)
}

@media (max-width: 767.0px) {

.SkipBtnPosition {
    bottom: auto;
    top: 10%
}
  }