:import('../../shared/styles/vars.css') {
  __value__cWhite__0: cWhite;
  __value__cTradewind__1: cTradewind;
  __value__wLangBtn__2: wLangBtn;
  __value__wLangBtnMobile__3: wLangBtnMobile
}

:export {
  cWhite: __value__cWhite__0;
  cTradewind: __value__cTradewind__1;
  wLangBtn: __value__wLangBtn__2;
  wLangBtnMobile: __value__wLangBtnMobile__3
}

.WorldPage {
	width: 100%;
	height: calc(100vh - 135px)
}

:global(.AppOK) .WorldPage {
		height: calc(100vh - 160px)
}

.WorldPageTitle {
	margin-bottom: 10px;
}

.BackBtn {
	position: absolute;
	left: 0;
}

.ContentWrap {
	width: 290px;
	margin: 150px auto 0 auto
}

@media (max-width: 767.0px) {

.ContentWrap {
		margin-top: 90px
}
  }

@media (max-width: 767.0px) and (-webkit-min-device-pixel-ratio: 2) {

.ContentWrap {
			margin-top: 90px
}
  }

.PacksWrap {
	width: 100%;
	height: calc(100vh - 315px)
}

@media (max-width: 767.0px) {

.PacksWrap {
	  height: calc(100vh - 330px)
}
  }

@media (max-width: 767.0px) and (-webkit-min-device-pixel-ratio: 2) {

.PacksWrap {
	    height: calc(100vh - 330px)
}
  }

@media (max-width: 767.0px) {

:global(.AppOK) .PacksWrap {
	  	height: calc(100vh - 310px)
}
  }

.StarsCounter {
	margin: 0 auto 10px auto;
}
