:import('../../styles/vars.css') {
  __value__cWhite__0: cWhite;
}

:export {
  cWhite: __value__cWhite__0;
}

.LivesNumber {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
}

.LivesNumberText {
	color: __value__cWhite__0;
	margin-left: 8px;
	font-size: 20px;
	text-transform: uppercase;
}

.LivesNumberIcon {
	margin-top: -2px;
}
