:import('../../styles/vars.css') {
  __value__cWhite__0: cWhite;
}

:export {
  cWhite: __value__cWhite__0;
}

.StarsCounter {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: end;
	        align-items: flex-end;
	-webkit-box-pack: center;
	        justify-content: center;
	color: __value__cWhite__0;
	font-size: 18px;
}

.StarsIcon {
	width: 20px;
	height: 20px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('/assets/img/star-filled-gold.svg');
	margin-right: 5px;
}
