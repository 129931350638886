:export {
  time: 1s;
}

/*
  forward transition = from right to left
  back transition = from left to right
*/

.modal-enter {
  opacity: 0;
}

.modal-enter-active, .modal-enter-done {
  opacity: 1;
  z-index: 10;
  -webkit-transition: opacity 1s ease, z-index 0.01s ease;
  transition: opacity 1s ease, z-index 0.01s ease;
}

.modal-exit {
  opacity: 1;
  z-index: 10;
}

.modal-exit-active, .modal-exit-done {
  opacity: 0;
  z-index: -1;
  -webkit-transition: opacity 1s ease, z-index 0.01s ease 1s;
  transition: opacity 1s ease, z-index 0.01s ease 1s;
}