

* {
  box-sizing: border-box;
  list-style: none;
}

html {
  font-size: 16px;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  font-family:'Gotham Pro', Arial, sans-serif;
  font-weight: 400px;
  overflow: hidden;
  /* remove scrollbar */
  /* Chrome */
  /* &::-webkit-scrollbar {
    display: none;
  } */
  /* Internet Explorer and Edge */
  /* -ms-overflow-style: none; */
}

a {
  text-decoration: none;
}


.BackBtn_BackBtn__KQPMr {
  width: 24px;
  height: 43px;
  background-image: url('/assets/img/back-button.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease
}

@media (max-width: 767.0px) {

.BackBtn_BackBtn__KQPMr {
    width: 18px;
    height: 29px
}
  }

@media (max-width: 370.0px) {

.BackBtn_BackBtn__KQPMr {
    width: 15px;
    height: 25px
}
  }

@media (min-width: 1024.0px) {
    .BackBtn_BackBtn__KQPMr:hover {
      -webkit-transform: scaleY(0.9);
              transform: scaleY(0.9);
    }
  }

.BackBtn_Reversed__1Q-dC {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg)
}

@media (min-width: 1024.0px) {
    .BackBtn_Reversed__1Q-dC:hover {
      -webkit-transform: scaleY(0.9) rotate(180deg);
              transform: scaleY(0.9) rotate(180deg);
    }
  }

.BackBtn_Transparent__1E67G {
  opacity: 0.44;
}


.UpdateBtn_UpdateBtn__3UUdo {
  background-image: url('/assets/img/update-icon.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  display: block;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out
}

@media (min-width: 1024.0px) {
    .UpdateBtn_UpdateBtn__3UUdo:hover {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
    }
  }

@media (max-width: 767.0px) {

.UpdateBtn_UpdateBtn__3UUdo {
    width: 35px;
    height: 35px
}
  }

@media (max-width: 370.0px) {

.UpdateBtn_UpdateBtn__3UUdo {
    width: 30px;
    height: 30px
}
  }

.UpdateBtn_Transparent__2it5I {
  opacity: 0.44;
}
/* colors */
/* header */
/* page */
/* sizes */
.Settings_Settings__GthuT {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
}

.Settings_SettingsBtn__3ujSU {
  width: 40px;
  height: 40px;
  background-image: url('/assets/img/settings-icon.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease
}

@media (max-width: 767.0px) {

.Settings_SettingsBtn__3ujSU {
    width: 30px;
    height: 30px
}
  }

@media (min-width: 1024.0px) {
    .Settings_SettingsBtn__3ujSU:hover {
      -webkit-transform: rotate(142deg);
              transform: rotate(142deg);
    }
  }

.UserLives_UserLives__B1xg_ {
	color: #FFFFFF;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	        justify-content: space-between;
	width: 300px
}
@media (max-width: 767.0px) {
  .UserLives_UserLives__B1xg_ {
		width: 180px
}
  }



.LifeIcon_LifeIcon__2AT37 {
  background-image: url('/assets/img/life-icon.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 23px;
  height: 20px;
  display: block;
}

.LifeIcon_SizeBig__1WN03 {
	width: 63px;
	height: 60px;
}

.LifeIcon_ColorGrey__1IlA9 {
  background-image: url('/assets/img/life-icon-grey.svg');
}
.LivesNumber_LivesNumber__3BlZE {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
}

.LivesNumber_LivesNumberText__2wNLh {
	color: #FFFFFF;
	margin-left: 8px;
	font-size: 20px;
	text-transform: uppercase;
}

.LivesNumber_LivesNumberIcon__Yj2hz {
	margin-top: -2px;
}

.NewLifeTime_NewLifeTime__omXWw {
	color: #FFFFFF;
	font-size: 20px;
	text-transform: uppercase;
	width: 56px;
}

.AddBtn_AddBtn__3inbU {
  position: relative;
  width: 43px;
  height: 43px;
  padding: 0;
  background-color: rgba(255, 255, 255, .2);
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  outline: none;
  -webkit-transition: border-color 0.3s ease-out;
  transition: border-color 0.3s ease-out
}

@media (max-width: 767.0px) {

.AddBtn_AddBtn__3inbU {
    width: 30px;
    height: 30px
}
  }

@media (min-width: 1024.0px) {
    .AddBtn_AddBtn__3inbU:hover:not(.AddBtn_AddBtnDisabled__28kOp) {
      border-color: rgba(255, 255, 255, 0.4);
    }
    .AddBtn_AddBtn__3inbU:hover:not(.AddBtn_AddBtnDisabled__28kOp) .AddBtn_AddBtnPlusIcon__1o6Vh {
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg);
    }
  }

.AddBtn_AddBtnPlusIcon__1o6Vh {
  background-image: url('/assets/img/plus-icon.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  width: 23px;
  height: 24px;
  -webkit-transform: none;
          transform: none;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out
}

@media (max-width: 767.0px) {

.AddBtn_AddBtnPlusIcon__1o6Vh {
    width: 15px;
    height: 15px
}
  }

.AddBtn_AddBtnDisabled__28kOp {
  opacity: 0.6;
  cursor: default;
}

.PayBtn_PayBtn__1sGzr {
  position: relative;
  width: 43px;
  height: 43px;
  padding: 0;
  background-color: rgba(255, 255, 255, .2);
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  outline: none;
  -webkit-transition: border-color 0.3s ease-out;
  transition: border-color 0.3s ease-out;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 800
}


@media (max-width: 767.0px) {


.PayBtn_PayBtn__1sGzr {
    width: 30px;
    height: 30px;
    font-size: 16px
}
  }


@media (min-width: 1024.0px) {
    .PayBtn_PayBtn__1sGzr:hover:not(.PayBtn_PayBtnDisabled__1wMyj) {
      border-color: rgba(255, 255, 255, 0.4);
    }
  }


.PayBtn_Icon__2FWQF {
  width: 100%;
  box-sizing: border-box;
  padding: 2px;
}

.Header_Header__1xrSh {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	height: 58px;
	max-width: 780px;
}


.Header_HeaderTitle__mOAgj {
  color: #FFFFFF;
  font-size: 20px;
  text-align: center;
}


.Header_BackBtn__EF6Sr {
  position: absolute;
  left: 0;
}


.Header_UpdateBtn__16sBI {
  position: absolute;
  left: 50px
}


@media (max-width: 767.0px) {


.Header_UpdateBtn__16sBI {
    left: 35px
}
  }


@media (max-width: 370.0px) {


.Header_UpdateBtn__16sBI {
    left: 30px
}
  }


.Header_Settigs__2juOz {
	position: absolute;
	right: 0;
}

.OKShareBtn_OKShareBtn__3Uvz9 {
  width: 137.75px;
  height: 32px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  background-color: #ed7a00;
  background: -webkit-gradient(linear, left bottom, left top, from(#ed7a00) , to(#ffa049));
  background: -webkit-linear-gradient(bottom, #ed7a00 , #ffa049);
  background: linear-gradient(to top, #ed7a00 , #ffa049);
  padding: 0 10px 0 25px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 11px;
  color: #FFFFFF;
  border-radius: 0;
  cursor: pointer;
  position: relative;
}

.OKShareBtn_OK__uqgxU {
  width: 20px;
  height: 20px;
  background-image: url('/assets/img/ok-icon.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.OKShareBtn_OKShareBtnTitle__17jfU {
  line-height: 10px;
}

.Footer_Footer__238hM {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
}

.Footer_Line__3sS2W {
  width: 90%;
  max-width: 290px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center
}

.Footer_Line__3sS2W:not(:last-child) {
    margin-bottom: 3px;
  }

.Footer_Contacts__3RMt0, .Footer_Sharings__1Uzq3 {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
}

.Footer_Tw__2r9PQ {
  width: 40px;
  height: 40px;
  background-image: url('/assets/img/tw.svg');
  background-size: 25px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.Footer_Fb__3kiqL {
  width: 40px;
  height: 40px;
  background-image: url('/assets/img/fb.svg');
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.Footer_Mail__3ZX7B {
  width: 40px;
  height: 40px;
  background-image: url('/assets/img/mail-1.svg');
  background-size: 25px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.Footer_Reddit__1UHqe {
  width: 40px;
  height: 40px;
  background-image: url('/assets/img/reddit.svg');
  background-size: 25px 25px;
  background-position: center;
  background-repeat: no-repeat;
}

.Footer_OK__3QBUp {
  width: 30px;
  height: 30px;
  background-image: url('/assets/img/ok-icon.svg');
  background-size: 25px 25px;
  background-position: center;
  background-repeat: no-repeat;
}

.Footer_ShareBtn__22WcQ {
  width: 60px;
  height: 40px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center
}

.Footer_ShareBtn__22WcQ:not(:last-child) {
    margin-right: 5px;
  }

.Footer_OKShareBtn__1_zHh {
  background-color: #ed7a00;
  background: -webkit-gradient(linear, left bottom, left top, from(#ed7a00) , to(#ffa049));
  background: -webkit-linear-gradient(bottom, #ed7a00 , #ffa049);
  background: linear-gradient(to top, #ed7a00 , #ffa049);
  padding: 0 10px 0 5px;
  height: 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 11px;
  color: #FFFFFF;
  border-radius: 20px;
  cursor: pointer
}

.Footer_OKShareBtn__1_zHh .Footer_OK__3QBUp {
    width: 20px;
    height: 20px;
    background-size: contain;
  }

.Footer_OKShareBtnTitle__UCwJY {
  margin-left: 5px;
}

.Footer_Info__2cltk {
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 1px;
  line-height: 1.3;
  width: 100%;
  text-align: center;
}

.Footer_Masked__19nNZ {
  opacity: 0.5;
}

.Footer_FooterLink__2S6Qk {
  text-decoration: none;
  color: #FFFFFF
}

.Footer_FooterLink__2S6Qk:hover {
    text-decoration: underline;
  }

.Footer_Link__2v7FB {
  color: #FFFFFF;
}

.Footer_Review__1Ywtb {
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 100;
  line-height: 1.4;
  margin: 10px 0 0
}

@media (max-width: 767.0px) {

.Footer_Review__1Ywtb {
    position: fixed;
    right: 10px;
    bottom: 15px;
    width: 30px
}
  }

.Footer_ReviewLink__D24Ul {
  display: inline-block;
  background-image: url(/assets/img/review-icon.svg);
  background-size: 20px 20px;
  background-position: left top;
  background-repeat: no-repeat;
  padding: 8px 10px 8px 25px
}

@media (max-width: 767.0px) {

.Footer_ReviewLink__D24Ul {
    font-size: 0;
    padding: 15px;
    background-size: 30px
}
  }

/* colors */
/* header */
/* page */
/* sizes */
/* Preloader from https://github.com/tobiasahlin/SpinKit */

.sk-circle {
  width: 40px;
  height: 40px;
  position: relative
}

.sk-circle.sizeBig {
    width: 80px;
    height: 80px;
  }

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #FFFFFF;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg)
  }

.sk-circle .sk-circle2:before {
      -webkit-animation-delay: -1.1s;
              animation-delay: -1.1s;
    }

.sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg)
  }

.sk-circle .sk-circle3:before {
      -webkit-animation-delay: -1s;
              animation-delay: -1s;
    }

.sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg)
  }

.sk-circle .sk-circle4:before {
      -webkit-animation-delay: -0.9s;
              animation-delay: -0.9s;
    }

.sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg)
  }

.sk-circle .sk-circle5:before {
      -webkit-animation-delay: -0.8s;
              animation-delay: -0.8s;
    }

.sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
            transform: rotate(150deg)
  }

.sk-circle .sk-circle6:before {
      -webkit-animation-delay: -0.7s;
              animation-delay: -0.7s;
    }

.sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg)
  }

.sk-circle .sk-circle7:before {
      -webkit-animation-delay: -0.6s;
              animation-delay: -0.6s;
    }

.sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
            transform: rotate(210deg)
  }

.sk-circle .sk-circle8:before {
      -webkit-animation-delay: -0.5s;
              animation-delay: -0.5s;
    }

.sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
            transform: rotate(240deg)
  }

.sk-circle .sk-circle9:before {
      -webkit-animation-delay: -0.4s;
              animation-delay: -0.4s;
    }

.sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg)
  }

.sk-circle .sk-circle10:before {
      -webkit-animation-delay: -0.3s;
              animation-delay: -0.3s;
    }

.sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
            transform: rotate(300deg)
  }

.sk-circle .sk-circle11:before {
      -webkit-animation-delay: -0.2s;
              animation-delay: -0.2s;
    }

.sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
            transform: rotate(330deg)
  }

.sk-circle .sk-circle12:before {
      -webkit-animation-delay: -0.1s;
              animation-delay: -0.1s;
    }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.Page_Page__2w4K- {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  will-change: transform, opacity;
}
.Hint_Hint__3nGsC{
  position: relative;
}

.Hint_HintWrap__mNAdS {
  width: 100%;
  height: 100%;
}

.Hint_TextWrap__gdV-h {
  position: absolute;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center;
  opacity: 0;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in
}

.Hint_TextWrap__gdV-h.Hint_Visible__SiBhZ {
    opacity: 1;
  }

.Hint_Text__1qGhe {
  color: #FFFFFF;
  text-align: center;
}

.Hint_Btn__1VTpJ {
  background-color: #FFFFFF;
  color: #828282;
  border-radius: 2px;
  font-size: 14px;
  padding: 10px 0;
  min-width: 100px;
  text-align: center;
  font-family:'Gotham Pro';
  font-weight: 500;
  margin-top: 14px;
  cursor: pointer
}

.Hint_Btn__1VTpJ:focus {
    outline: none;
  }

.Hint_Btn__1VTpJ.Hint_Hidden__3yeEp {
    display: none;
  }

.Hint_SkipBtn__2HsyR {
  background: #6BBCB4;
  color: #FFFFFF;
  padding: 10px;
  margin: 0;
  outline: none;
  border: 1px solid white;
}

.Hint_SkipBtnPosition__3MV2T {
  position: absolute;
  bottom: 5%;
  right: 50%;
  -webkit-transform: translateX(50%);
          transform: translateX(50%)
}

@media (max-width: 767.0px) {

.Hint_SkipBtnPosition__3MV2T {
    bottom: auto;
    top: 10%
}
  }
.Tutorial_Tutorial__2VUcs {
  z-index: -1;
  -webkit-transition: z-index 300ms ease;
  transition: z-index 300ms ease
}


.Tutorial_Tutorial__2VUcs.Tutorial_HintShow__3BW8v {
    z-index: 100;
  }


.Tutorial_Hint__3mc_T {
  z-index: -1;
  opacity: 0;
  -webkit-transition: opacity 300ms ease, z-index 300ms ease;
  transition: opacity 300ms ease, z-index 300ms ease
}


.Tutorial_Hint__3mc_T.Tutorial_HintShow__3BW8v {
    opacity: 1;
    z-index: 200;
  }


.ModalManager_ModalManager__CHMez {

}

.ModalManager_Modal__3WOYm {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  -webkit-transition: opacity 1s ease, visibility 1s ease, z-index 1s ease;
  transition: opacity 1s ease, visibility 1s ease, z-index 1s ease
}

.ModalManager_Modal__3WOYm.ModalManager_Show__1Rs4j {
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }
/*
  forward transition = from right to left
  back transition = from left to right
*/

.modal-enter {
  opacity: 0;
}

.modal-enter-active, .modal-enter-done {
  opacity: 1;
  z-index: 10;
  -webkit-transition: opacity 1s ease, z-index 0.01s ease;
  transition: opacity 1s ease, z-index 0.01s ease;
}

.modal-exit {
  opacity: 1;
  z-index: 10;
}

.modal-exit-active, .modal-exit-done {
  opacity: 0;
  z-index: -1;
  -webkit-transition: opacity 1s ease, z-index 0.01s ease 1s;
  transition: opacity 1s ease, z-index 0.01s ease 1s;
}
.App_App__16ZpL {
  width: 100vw;
  position: relative;
  overflow: hidden;
  background-color: #6BBCB4;
}
.App_PageWrap__2a8e6 {
  position: relative;
  padding: 65px 0 0;
  width: 100%;
  height: 100%;
  z-index: 1
}
@media (max-width: 767.0px) and (-webkit-min-device-pixel-ratio: 2) {
.App_PageWrap__2a8e6 {
      padding: 65px 0 80px
}
  }
.App_Header__ZS1m2 {
	position: absolute;
	top: 10px;
	left: 20px;
	right: 20px;
  margin: 0 auto;
  z-index: 2;
}
.App_Footer__XLEFd {
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  z-index: 2;
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s, visibility 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s, visibility 0.3s ease 0.5s
  /* @mixin mediaTablet {
    bottom: 80px;
  } */
}
@media (max-width: 1024.0px) {
.App_Footer__XLEFd.App_OK__2NFXZ {
      bottom: 108px
  }
  }
.App_FooterHidden__tK5MW {
  -webkit-transition: opacity 0.3s ease, visibility 0.3s ease;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 0;
  visibility: hidden;
}
.App_CheatPanel__3-GA5 {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0
}
@media (max-width: 1024.0px) {
.App_CheatPanel__3-GA5 {
    top: auto;
    bottom: 0
}
  }
.App_PreloaderWrap__3rdGY {
  background: #6BBCB4;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  width: 100vw;
  height: 100vh;
}
.App_Tutorial__1Qmgx {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

/*
  forward transition = from right to left
  back transition = from left to right
*/

/**
 * After changing animation time, we also need to change it in shared/constants.js const PAGE_ANIMATION_TIME
 */

.page-forward-enter {
  opacity: 0;
  z-index: 1;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}

.page-forward-enter.page-forward-enter-active,
.page-back-enter.page-back-enter-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.page-forward-exit,
.page-back-exit {
  opacity: 1;
}

.page-forward-exit.page-forward-exit-active {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
  -webkit-transition: opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.page-back-enter {
  opacity: 0;
  z-index: 1;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
}

.page-back-exit.page-back-exit-active {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  -webkit-transition: opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}
.CustomListItem_CustomListItem__3b8jp {
	font-size: 18px;
	color: #FFFFFF;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	        justify-content: space-between;
	padding: 0 25px 0 15px;
	background-color: rgba(255, 255, 255, 0.2);
	border: 1px solid transparent;
	height: 49px;
	margin-bottom: 16px;
	position: relative;
	-webkit-transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
	transition: background-color 0.3s ease-out, border-color 0.3s ease-out
}


.CustomListItem_CustomListItem__3b8jp:not(.CustomListItem_Blocked__15spM) {
		cursor: pointer;
	}


@media (min-width: 1024.0px) {
	  .CustomListItem_CustomListItem__3b8jp:not(.CustomListItem_Blocked__15spM):hover {
	    background-color: rgba(255, 255, 255, 0.1);
	    border-color: rgba(255, 255, 255, 0.4);
	  }
  }


.CustomListItem_CustomListItemTitle__53r3c {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}


.CustomListItem_CustomListItemRight__BxxVz {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	        justify-content: space-between;
	text-align: right;
}


.CustomListItem_Virtual__27s8R .CustomListItem_CustomListItemRight__BxxVz {
	width: 50px;
}


.CustomListItem_CustomListItemStarsInfo__Kzg-V {
	margin-top: 3px;
	width: 62px;
}


.CustomListItem_CustomListItemStarsRequired__3TYIO {
	margin-top: 3px;
	width: 34px;
}


.CustomListItem_Virtual__27s8R .CustomListItem_CustomListItemStarsRequired__3TYIO {
	display: none;
}


.CustomListItem_StarIcon__2vm6l {
	width: 20px;
	height: 20px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('/assets/img/star-filled-gold.svg');
	margin-right: 5px;
}


.CustomListItem_Virtual__27s8R .CustomListItem_StarIcon__2vm6l {
	display: none;
}


.CustomListItem_StarIconGrey__3loKY {
	background-image: url('/assets/img/star-filled-grey.svg');
	margin-right: 8px;
}


.CustomListItem_Blocked__15spM {
	color: #808081;
}


.CustomListItem_SizeSmall__N2gkP .CustomListItem_CustomListItemStarsInfo__Kzg-V {
		font-size: 14px;
	}


.CustomListItem_LockIcon__2W1XK {
	width: 20px;
	height: 20px;
	background-image: url('/assets/img/lock-icon-grey.svg');
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-color: transparent;
	margin-right: 5px;
}


.CustomListItem_Hint__1fS6N {
	position: absolute;
	top: -45%;
	width: 60%;
	padding: 10px;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	left: 50%;
	background-color: cTradewind;
	color: #FFFFFF;
	border: 1px solid hsla(0,0%,100%, 0.5);
	text-align: center;
	font-size: 15px;
	line-height: 1.2;
	font-style: italic;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity 0.3s ease, visibility 0.3s ease, left 0.3s ease;
	transition: opacity 0.3s ease, visibility 0.3s ease, left 0.3s ease;
}

.LandingPage_LandingPage__SzWht {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center;
  justify-content: space-around;
  height: calc(100vh - 165px);
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 65px
}


@media (max-width: 767.0px) and (-webkit-min-device-pixel-ratio: 2) {


.LandingPage_LandingPage__SzWht {
      height: calc(100vh - 175px);
      padding-top: 26px
}
  }


.LandingPage_LogoWrap__3kXfC {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center;
}


.LandingPage_Logo__2uGVc {
  width: 73px;
  height: 35px;
  background-image: url('/assets/img/logo-small.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 20px;
}


.LandingPage_LogoText__2fRSV {
  width: 300px;
  height: 42px;
  background-image: url('/assets/img/logo-text.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}


.LandingPage_Title__100ae {
  margin-bottom: 50px;
}


.LandingPage_BtnsWrap__1XW6W {}


.LandingPage_Btn__1ErwP:not(:last-child) {
    margin-bottom: 10px;
  }


.LandingPage_Text__x8Pzh {
  font-size: 11px;
  font-weight: 100;
  letter-spacing: 0.5px;
  line-height: 16px;
  color: #FFFFFF;
  padding: 0 15px;
  text-align: center
}


@media (max-width: 677.0px) {


.LandingPage_Text__x8Pzh {
    padding: 0 12px;
    font-size: 10px;
    margin-bottom: 10px
}
  }


.LandingPage_Link__9njmX {
  text-decoration: underline;
  color: #FFFFFF;
}


.LandingPage_WorldsWrap__DznCb {
  width: 320px;
  height: calc(100vh - 450px);
  margin: 0 auto
}


@media (max-width: 767.0px) {


.LandingPage_WorldsWrap__DznCb {
    height: calc(100vh - 435px)
}


.AppOK .LandingPage_WorldsWrap__DznCb {
      height: calc(100vh - 400px)
}
  }

.Stars_StarsWrap__2noun {
	margin: 0 auto;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
}


@media (max-width: 767.0px) {
    .Stars_ResizeOnMobile__z9Gpc .Stars_Star__2zudH {
    	width: 12px;
    	height: 12px;
    	margin: 0 2px;
    }
  }


.Stars_Star__2zudH {
	width: 24px;
	height: 23px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	margin: 0 3%;
}


.Stars_StarOutline__rHgmw {
	background-image: url('/assets/img/star-outline.svg');
}


.Stars_StarFilled__Nsf8R {
	background-image: url('/assets/img/star-filled-white.svg');
}

.PageTitle_PageTitle__3GkWv {
	color: #FFFFFF;
	font-size: 20px;
	text-align: center;
}

.headerSlash {
  font-size: 15px;
  position: relative;
  top: -2px;
}
.StarsCounter_StarsCounter__1hQku {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: end;
	        align-items: flex-end;
	-webkit-box-pack: center;
	        justify-content: center;
	color: #FFFFFF;
	font-size: 18px;
}

.StarsCounter_StarsIcon__1jZSC {
	width: 20px;
	height: 20px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('/assets/img/star-filled-gold.svg');
	margin-right: 5px;
}

@-webkit-keyframes LevelsPage_levelsShow__2wdXZ {
  0%   {opacity: 0}
  100% {opacity: 1}
}

@keyframes LevelsPage_levelsShow__2wdXZ {
  0%   {opacity: 0}
  100% {opacity: 1}
}

.LevelsPage_LevelsPage__2gl8a {
  width: 100%;
  padding: 58px 20px 0;
  height: calc(100vh - 135px)
}

.AppOK .LevelsPage_LevelsPage__2gl8a {
    height: calc(100vh - 160px)
}

.LevelsPage_LevelsPageTitle__3LinY {
  margin-bottom: 10px;
}

.LevelsPage_LevelsContent__1_fR2 {
  padding-top: 37px;
  -webkit-animation: LevelsPage_levelsShow__2wdXZ 1s ease;
          animation: LevelsPage_levelsShow__2wdXZ 1s ease
}

@media (max-width: 1024.0px) {

.LevelsPage_LevelsContent__1_fR2 {
    padding-top: 32px
}
  }

.LevelsPage_LevelsListScrollWrap__2-b7K {
  height: calc(100vh - 315px)
}

@media (max-width: 1024.0px) {

.LevelsPage_LevelsListScrollWrap__2-b7K {
    height: calc(100vh - 330px)
}
  }

@media (max-width: 767.0px) {

.LevelsPage_LevelsListScrollWrap__2-b7K {
    height: calc(100vh - 330px)
}
  }

@media (max-width: 767.0px) and (-webkit-min-device-pixel-ratio: 2) {

.LevelsPage_LevelsListScrollWrap__2-b7K {
      height: calc(100vh - 330px)
}
  }

.LevelsPage_LevelsListScrollWrap__2-b7K.LevelsPage_OK__2rx_G {
    /*padding-top: 37px;*/
    height: calc(100vh - 255px)
    /*animation: levelsShow 1s ease;*/
  }

@media (max-width: 1024.0px) {

.LevelsPage_LevelsListScrollWrap__2-b7K.LevelsPage_OK__2rx_G {
      /*padding-top: 32px;*/
      height: calc(100vh - 250px)
  }
  }

@media (max-width: 1024.0px) and (-webkit-min-device-pixel-ratio: 2) {

.LevelsPage_LevelsListScrollWrap__2-b7K.LevelsPage_OK__2rx_G {
        height: calc(100vh - 250px)
  }
  }

@media (max-width: 1024.0px) {

.AppOK .LevelsPage_LevelsListScrollWrap__2-b7K.LevelsPage_OK__2rx_G {
        height: calc(100vh - 310px)
  }
  }

.LevelsPage_LevelList__12ZkA {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
          justify-content: center;
  max-width: 850px;
  margin: 0 auto;
}

.LevelsPage_LevelItem__8phR8 {
  width: 135px;
  height: 85px;
  padding: 5px 8px;
  margin: 0 13px 33px 13px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease
}

@media (min-width: 1024.0px) {
    .LevelsPage_LevelItem__8phR8:hover:not(.LevelsPage_Locked__ISMFM) {
      background-color: rgba(253, 253, 253, 0.4);
    }
  }

@media (max-width: 767.0px) {

.LevelsPage_LevelItem__8phR8 {
    width: 43px;
    height: 43px;
    margin: 0 4px 17px 4px
}
  }

.LevelsPage_LevelItemWrap__1RhR_ {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  height: 100%;
  cursor: pointer;
  padding: 10px 0
}

@media (max-width: 767.0px) {

.LevelsPage_LevelItemWrap__1RhR_ {
    padding: 0
}
  }

.LevelsPage_LevelItemNumber__24ZgK {
  font-size: 32px;
  color: #FFFFFF;
  text-align: center
}

@media (max-width: 767.0px) {

.LevelsPage_LevelItemNumber__24ZgK {
    font-size: 22px
}
  }

.LevelsPage_LevelItemStars__2cPBC {
  width: 100%;
}

.LevelsPage_BackBtn__3ndPH {
  position: absolute;
  left: 0;
}

.LevelsPage_Completed__1T69D {
  background-color: blue;
}

.LevelsPage_Locked__ISMFM {
  opacity: 0.6
}

.LevelsPage_Locked__ISMFM .LevelsPage_LevelItemWrap__1RhR_ {
    cursor: default;
  }

.LevelsPage_ToBeContinued__xkQgW {
  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.LevelsPage_LevelLocked__2xbR9 {
  width: 24px;
  height: 24px;
  background-image: url('/assets/img/lock-icon.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent
}

@media (max-width: 767.0px) {

.LevelsPage_LevelLocked__2xbR9 {
    width: 11px;
    height: 11px
}
  }

.LevelsPage_StarsCounter__3Ub9C {
  margin: 0 auto 10px auto;
}

.WorldPage_WorldPage__31cLX {
	width: 100%;
	height: calc(100vh - 135px)
}

.AppOK .WorldPage_WorldPage__31cLX {
		height: calc(100vh - 160px)
}

.WorldPage_WorldPageTitle__aqpQx {
	margin-bottom: 10px;
}

.WorldPage_BackBtn__1qROa {
	position: absolute;
	left: 0;
}

.WorldPage_ContentWrap__q3p6n {
	width: 290px;
	margin: 150px auto 0 auto
}

@media (max-width: 767.0px) {

.WorldPage_ContentWrap__q3p6n {
		margin-top: 90px
}
  }

@media (max-width: 767.0px) and (-webkit-min-device-pixel-ratio: 2) {

.WorldPage_ContentWrap__q3p6n {
			margin-top: 90px
}
  }

.WorldPage_PacksWrap__1xMeI {
	width: 100%;
	height: calc(100vh - 315px)
}

@media (max-width: 767.0px) {

.WorldPage_PacksWrap__1xMeI {
	  height: calc(100vh - 330px)
}
  }

@media (max-width: 767.0px) and (-webkit-min-device-pixel-ratio: 2) {

.WorldPage_PacksWrap__1xMeI {
	    height: calc(100vh - 330px)
}
  }

@media (max-width: 767.0px) {

.AppOK .WorldPage_PacksWrap__1xMeI {
	  	height: calc(100vh - 310px)
}
  }

.WorldPage_StarsCounter__13fOK {
	margin: 0 auto 10px auto;
}



/***********************************
************* REGULAR **************
***********************************/

@font-face {
    font-family:'Gotham Pro';
	src: url('/assets/fonts/GothaProReg.eot');
	src: local('☺'), url('/assets/fonts/GothaProReg.woff') format('woff'), url('/assets/fonts/GothaProReg.ttf') format('truetype'), url('/assets/fonts/GothaProReg.svg') format('svg');
	font-weight: normal;
    font-style: normal;
    font-display: swap;
}
