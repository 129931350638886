:import('../../shared/styles/vars.css') {
  __value__headerMaxWidth__0: headerMaxWidth;
}


:export {
  headerMaxWidth: __value__headerMaxWidth__0;
}


.Header {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	        justify-content: center;
	height: 58px;
	max-width: __value__headerMaxWidth__0;
}


.HeaderTitle {
  color: #FFFFFF;
  font-size: 20px;
  text-align: center;
}


.BackBtn {
  position: absolute;
  left: 0;
}


.UpdateBtn {
  position: absolute;
  left: 50px
}


@media (max-width: 767.0px) {


.UpdateBtn {
    left: 35px
}
  }


@media (max-width: 370.0px) {


.UpdateBtn {
    left: 30px
}
  }


.Settigs {
	position: absolute;
	right: 0;
}
