:import('../../shared/styles/vars.css') {
  __value__cWhite__0: cWhite;
  __value__cOKorange__1: cOKorange;
}

:export {
  cWhite: __value__cWhite__0;
  cOKorange: __value__cOKorange__1;
}

.Footer {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
}

.Line {
  width: 90%;
  max-width: 290px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center
}

.Line:not(:last-child) {
    margin-bottom: 3px;
  }

.Contacts, .Sharings {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
}

.Tw {
  width: 40px;
  height: 40px;
  background-image: url('/assets/img/tw.svg');
  background-size: 25px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.Fb {
  width: 40px;
  height: 40px;
  background-image: url('/assets/img/fb.svg');
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.Mail {
  width: 40px;
  height: 40px;
  background-image: url('/assets/img/mail-1.svg');
  background-size: 25px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.Reddit {
  width: 40px;
  height: 40px;
  background-image: url('/assets/img/reddit.svg');
  background-size: 25px 25px;
  background-position: center;
  background-repeat: no-repeat;
}

.OK {
  width: 30px;
  height: 30px;
  background-image: url('/assets/img/ok-icon.svg');
  background-size: 25px 25px;
  background-position: center;
  background-repeat: no-repeat;
}

.ShareBtn {
  width: 60px;
  height: 40px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center
}

.ShareBtn:not(:last-child) {
    margin-right: 5px;
  }

.OKShareBtn {
  background-color: #ed7a00;
  background: -webkit-gradient(linear, left bottom, left top, from(#ed7a00) , to(#ffa049));
  background: -webkit-linear-gradient(bottom, #ed7a00 , #ffa049);
  background: linear-gradient(to top, #ed7a00 , #ffa049);
  padding: 0 10px 0 5px;
  height: 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 11px;
  color: __value__cWhite__0;
  border-radius: 20px;
  cursor: pointer
}

.OKShareBtn .OK {
    width: 20px;
    height: 20px;
    background-size: contain;
  }

.OKShareBtnTitle {
  margin-left: 5px;
}

.Info {
  color: __value__cWhite__0;
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 1px;
  line-height: 1.3;
  width: 100%;
  text-align: center;
}

.Masked {
  opacity: 0.5;
}

.FooterLink {
  text-decoration: none;
  color: __value__cWhite__0
}

.FooterLink:hover {
    text-decoration: underline;
  }

.Link {
  color: __value__cWhite__0;
}

.Review {
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 100;
  line-height: 1.4;
  margin: 10px 0 0
}

@media (max-width: 767.0px) {

.Review {
    position: fixed;
    right: 10px;
    bottom: 15px;
    width: 30px
}
  }

.ReviewLink {
  display: inline-block;
  background-image: url(/assets/img/review-icon.svg);
  background-size: 20px 20px;
  background-position: left top;
  background-repeat: no-repeat;
  padding: 8px 10px 8px 25px
}

@media (max-width: 767.0px) {

.ReviewLink {
    font-size: 0;
    padding: 15px;
    background-size: 30px
}
  }
