:export {
  appearTime: 300ms
}


.Tutorial {
  z-index: -1;
  -webkit-transition: z-index 300ms ease;
  transition: z-index 300ms ease
}


.Tutorial.HintShow {
    z-index: 100;
  }


.Hint {
  z-index: -1;
  opacity: 0;
  -webkit-transition: opacity 300ms ease, z-index 300ms ease;
  transition: opacity 300ms ease, z-index 300ms ease
}


.Hint.HintShow {
    opacity: 1;
    z-index: 200;
  }