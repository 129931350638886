:import('../../styles/vars.css') {
  __value__cWhite__0: cWhite
}


:export {
  cWhite: __value__cWhite__0
}


.PayBtn {
  position: relative;
  width: 43px;
  height: 43px;
  padding: 0;
  background-color: rgba(255, 255, 255, .2);
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  outline: none;
  -webkit-transition: border-color 0.3s ease-out;
  transition: border-color 0.3s ease-out;
  color: __value__cWhite__0;
  font-size: 18px;
  font-weight: 800
}


@media (max-width: 767.0px) {


.PayBtn {
    width: 30px;
    height: 30px;
    font-size: 16px
}
  }


@media (min-width: 1024.0px) {
    .PayBtn:hover:not(.PayBtnDisabled) {
      border-color: rgba(255, 255, 255, 0.4);
    }
  }


.Icon {
  width: 100%;
  box-sizing: border-box;
  padding: 2px;
}
