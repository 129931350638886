

.LifeIcon {
  background-image: url('/assets/img/life-icon.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 23px;
  height: 20px;
  display: block;
}

.SizeBig {
	width: 63px;
	height: 60px;
}

.ColorGrey {
  background-image: url('/assets/img/life-icon-grey.svg');
}